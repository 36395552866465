













import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class Tabs extends Vue {
  @Prop({
    default: () => {
      return [
        {
          id: 1,
          title: "家庭能源",
        },
        {
          id: 2,
          title: "商业储能",
        },
        {
          id: 3,
          title: "公用机构储能",
        },
        {
          id: 4,
          title: "工业储能",
        },
      ];
    },
  })
  readonly list!: [];
  isTabFix = false;
  prevScroll = 0;
  isActive = 1;

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  judgeIsShow(el: any) {
    if (!el) return false;
    let docEl = document.documentElement;
    let oneRect = el!.getBoundingClientRect();
    return (
      oneRect.top >= (window.innerHeight || docEl.clientHeight) ||
      oneRect.bottom <= 0
    );
  }
  handleScroll() {
    let scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    let docEl = document.documentElement;
    let width = docEl.getBoundingClientRect().width;
    let judgeOne = this.judgeIsShow(document.querySelector("#one")),
      judgeTwo = this.judgeIsShow(document.querySelector("#two")),
      judgeThree = this.judgeIsShow(document.querySelector("#three")),
      judgefour = this.judgeIsShow(document.querySelector("#four"));
    if (!judgeOne) {
      this.isActive = 1;
    } else if (!judgeTwo) {
      this.isActive = 2;
    } else if (!judgeThree) {
      this.isActive = 3;
    } else if (!judgefour) {
      this.isActive = 4;
    }
    let $rem = width / 375;
    if (scrollTop > 390 * $rem) {
      // console.log("scrollTop", scrollTop);
      this.isTabFix = true;
    } else {
      this.isTabFix = false;
    }
  }
}
